import React from "react"
import AniLink from "gatsby-plugin-transition-link"
import { graphql } from "gatsby"
import Layout from "../components/layout/Layout"
import styles from "./blog-template.module.css"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import SEO from "../components/SEO"

const BlogTemplate = ({ data }) => {

  const { title, createdAt, blogContent: { json }, seoTitle, seoDescription, seoAuthor, seoUrl, seoImage } = data.post
  const siteUrl = 'https://www.nolanjames.me/blog/'
  const options = {
    renderNode: {
      "embedded-asset-block": (node) => {
        return <div className={styles.postImages}>
          <img width="400" src={node.data.target.fields.file["en-US"].url} alt='blog post'/>
        </div>
      },
    },
  }

  return (
    <Layout>
      <SEO title={seoTitle} description={seoDescription} author={seoAuthor} image={seoImage.fluid} siteUrl={`${siteUrl}${seoUrl}`}/>
      <section className={styles.blog}>
        <div className={styles.center}>
          <h1>{title}</h1>
          <h4>Published: {createdAt}</h4>
          <article className={styles.post}>
            {documentToReactComponents(json, options)}
          </article>
          <AniLink fade to='/blog' className='btn-primary'>All Posts</AniLink>
        </div>
      </section>
    </Layout>
  )
}

export const query = graphql`
    query getPost($slug: String!){
        post: contentfulBlog(slug: {eq: $slug}) {
            title
            createdAt(formatString: "Do MMMM, Y")
            blogContent {
                json
            }
            seoTitle
            seoDescription
            seoAuthor
            seoUrl
            seoKeywords
            seoImage {
                fluid {
                    ...GatsbyContentfulFluid_withWebp
                }
            }
        }
    }

`

export default BlogTemplate
